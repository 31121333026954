/**
 * A collection of block identifiers used in the application.
 * Each block represents a specific functionality or view within the system.
 *
 * @property CLIENT_SHARE_LINK_FORM - Represents the block for the "Client Share Link Form".
 *   This block is used to display a sharable link form by sharing a link to clients.
 *
 * @property INFORMATION_REQUEST_LIST - Represents the block for the "Information Request List".
 *   This block is used to display a list of information requests.
 *
 * @property INFORMATION_REQUEST_GRANT_ACCESS_LIST - Represents the block for the "Grant Access List".
 *   This block is used to display access request for sharable link forms on the grant access page.
 */
export const BLOCKS: {[key: string]: string} = {
    CLIENT_SHARE_LINK_FORM: 'urn:block:informationrequest:view:client-share-link-form',
    INFORMATION_REQUEST_LIST: 'urn:block:informationrequest:view:list',
    INFORMATION_REQUEST_GRANT_ACCESS_LIST: 'urn:block:informationrequest:view:grant-access-list',
};
