/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */
import {TabContextProps} from '@sharefiledev/flow-web';
import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package

import {InformationRequestSpec, SubmitHandler} from '../components/common/types';

const prefix = 'urn';
const namespace = 'sfextslot';
const drawerNamespace = 'sfdrawer';
const resourceType = 'inforeq';

export const informationRequestPiletExtensionSlots = {
    informationRequestContent: `${prefix}:${namespace}:informationrequest:content:view` as const,
    projectTab:
        `${prefix}:${namespace}:informationrequest:project:tab:information-request` as const,
    shareButton: `${prefix}:${namespace}:informationrequest:share-button` as const,
    connectedEntityAutoComplete:
        `${prefix}:${namespace}:informationrequest:connected-entity-auto-complete` as const,
    specEditor: `${prefix}:${namespace}:${resourceType}:spec-editor` as const,
};

export const piletOverlays = {
    createInformationRequestFromBlank:
        `urn:${drawerNamespace}:create-information-request:blank` as const,
    informationRequest: `urn:${drawerNamespace}:task-aggregator:${resourceType}` as const,
    clientInformationRequest:
        `urn:${drawerNamespace}:task-aggregator:client:${resourceType}` as const,
};

export const AntDPrefix = 'ant';

export interface InformationRequestContentParams {
    containerRID: string;
    submitHandler: SubmitHandler;
}

export interface VerticalizationParams {
    verticalContextKey?: string;
}

export type TaskDrawerParams = {
    containerRID: string;
};

export type ClientTaskDrawerParams = {
    params: {containerRID: string};
};

type FlowWebTabExtensionImplementationParams<TParams> = TParams & {
    tabContext: TabContextProps;
    piletSortWeight?: number;
    isFrozen?: boolean;
};

export type ProjectDataTablesTabParams = FlowWebTabExtensionImplementationParams<{
    projectUri: string;
}>;

export interface CreateInformationRequestFromBlank {
    params: {containerRID: string; showCloseButton?: boolean};
}

export interface ShareButtonExtensionParams {
    resourceId: string;
}

export interface ConnectedEntityAutocompleteExtensionParams {
    connectedEntityRID: string;
    placeholder?: string;
    size?: string;
    defaultValue?: any;
    style?: any;
    onSelect: (value: unknown) => void;
    onBlur?: (value: unknown) => void;
    onError?: (value: unknown) => void;
    onClear?: () => void;
}

export interface SpecEditorExtensionParams {
    resourceSpec: InformationRequestSpec;
    onUpdate: (value: InformationRequestSpec) => void;
}

declare module '@sharefiledev/sharefile-appshell' {
    interface PiralCustomExtensionSlotMap {
        [informationRequestPiletExtensionSlots.informationRequestContent]: InformationRequestContentParams;
        [informationRequestPiletExtensionSlots.projectTab]: ProjectDataTablesTabParams;
        [informationRequestPiletExtensionSlots.shareButton]: ShareButtonExtensionParams;
        [informationRequestPiletExtensionSlots.connectedEntityAutoComplete]: ConnectedEntityAutocompleteExtensionParams;
        [informationRequestPiletExtensionSlots.specEditor]: SpecEditorExtensionParams;
    }

    interface PiralCustomOverlayOptionsMap {
        [piletOverlays.informationRequest]: TaskDrawerParams;
        [piletOverlays.clientInformationRequest]: ClientTaskDrawerParams;
        [piletOverlays.createInformationRequestFromBlank]: CreateInformationRequestFromBlank;
    }
}
