import {Skeleton, Spin} from 'antd';
import React from 'react';

import {CreateFromBlankDrawerProps} from '../../components/CreateInformationRequestDrawer/CreateFromBlank';
import {
    ClientShareLinkFormParams,
    CommonExtensionProps,
    InformationRequestListBlockParams,
    InfReqGrantAccessListParams,
} from './types';

const LazyLoadInformationRequestListBlock = React.lazy(
    () => import('./InformationRequestListBlock'),
);

const LazyLoadClientShareLinkFormBlock = React.lazy(() => import('./ClientShareLinkFormBlock'));
const LazyLoadInfReqGrantAccessListBlock = React.lazy(() => import('./InfReqGrantAccessListBlock'));

export const AsyncRenderInfReqGrantAccessListBlock: React.FC<
    CommonExtensionProps & InfReqGrantAccessListParams
> = (props) => (
    <React.Suspense fallback={<Spin />}>
        <LazyLoadInfReqGrantAccessListBlock {...props} />
    </React.Suspense>
);

export const AsyncInformationRequestListBlock: React.FC<
    CommonExtensionProps & InformationRequestListBlockParams
> = (props) => (
    <React.Suspense fallback={<Spin />}>
        <LazyLoadInformationRequestListBlock {...props} />
    </React.Suspense>
);

export const AsyncRenderClientShareLinkFormBlock: React.FC<
    CommonExtensionProps & ClientShareLinkFormParams
> = (props) => (
    <React.Suspense fallback={<Spin />}>
        <LazyLoadClientShareLinkFormBlock {...props} />
    </React.Suspense>
);

const LazyLoadCreateInformationRequestDrawerBlank = React.lazy(
    () => import('../../components/CreateInformationRequestDrawer/CreateFromBlank'),
);
export const AsyncCreateInformationRequestDrawerBlank: React.FC<CreateFromBlankDrawerProps> = (
    props,
) => (
    <React.Suspense fallback={<Skeleton />}>
        <LazyLoadCreateInformationRequestDrawerBlank {...props} />
    </React.Suspense>
);
