/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    ClientInformationRequest,
    InformationRequest,
    InformationRequestSchema,
    InformationRequestSpec,
    StatusBadgeOptions,
    ViewType,
} from '../components/common/types';
import {DataProvider, UpdateMetaRequestPayload, UpdateSchemaRequestPayload} from './types';

const informationRequestRID = 'sharefile:account-123/project-456/inforeq-789';
export const useSpecEditDataProvider = (
    resourceSpec: InformationRequestSpec,
    onUpdate: (value: InformationRequestSpec) => void,
): DataProvider => {
    let updatedSchema: InformationRequestSchema | undefined = undefined;

    return {
        getInformationRequests: async function (
            _containerId: string,
            _view?: ViewType,
            _isDeleted?: boolean,
            _take?: number,
            _skip?: number,
        ): Promise<any> {
            return [
                {
                    informationRequestRID,
                    informationRequestStatus: StatusBadgeOptions.Draft,
                    totalAssigned: 0,
                    totalSubmitted: 0,
                    containerID: informationRequestRID,
                    ...resourceSpec,
                },
            ];
        },
        getInformationRequestsByUser: function (): Promise<ClientInformationRequest[]> {
            throw new Error('Function not implemented.');
        },
        getInformationRequest: async function (
            _informationRequestRID: string,
        ): Promise<InformationRequest> {
            return {
                informationRequestRID,
                informationRequestStatus: StatusBadgeOptions.Draft,
                totalAssigned: 0,
                totalSubmitted: 0,
                containerID: informationRequestRID,
                ...resourceSpec,
            };
        },
        createInformationRequest: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        updateInformationRequestSchema: async function (
            _informationRequestRID: string,
            payload: UpdateSchemaRequestPayload,
        ): Promise<any> {
            updatedSchema = payload.informationRequestSchema; // updateInformationRequestMeta immediately called after, prevent two updates
        },
        updateInformationRequestMeta: async function (
            _informationRequestRID: string,
            payload: UpdateMetaRequestPayload,
        ): Promise<any> {
            onUpdate({
                ...resourceSpec,
                name: payload.name ?? '',
                description: payload.description,
                dueDate: payload.dueDate,
                thankYouMessage: payload.thankYouMessage as string,
                ...(updatedSchema && {informationRequestSchema: updatedSchema}),
            });
            updatedSchema = undefined;
        },
        setAssigneeToInformationRequest: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        getInformationResponses: async function (): Promise<any> {
            return [];
        },
        getInformationResponse: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        getInformationResponseByResponseRID: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        createInformationResponse: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        deleteAssigneeFromInformationRequest: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        deleteInformationRequest: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        closeInformationRequest: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        duplicateInformationRequest: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        restoreInformationRequest: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        remindAssigneesInformationRequest: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        getConnectedEntityData: function (): Promise<any> {
            throw new Error('Function not implemented.');
        },
        exportInformationResponses: function (): Promise<{
            filename: string;
            rowCount: number;
            content: string;
        }> {
            throw new Error('Function not implemented.');
        },
    };
};
